import React from "react";
import { Row, Col, Tab, Nav } from "react-bootstrap";
import testi from "../data/testi.json";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import "tailwindcss/tailwind.css";
import "../style/App.scss";
import blueCircle from "../media/OmxBlueCircle.png";
import orangeCircle from "../media/omxOrangeCircle.png";
import redCircle from "../media/OmxRedCircle.png";
import { useState } from "react";
import { SimpleModal } from "./SimpleModal";
import EmailModal from "./EmailModal";
import { styledToast } from "./StyledToast";
import { Toaster } from "react-hot-toast";

const ContractSubcontent = (props) => {
  const [buyModal, setBuyModal] = useState(false);

  return (
    <>
      <Toaster position="top-right" />

      <Row className="flex flex-col md:flex-row">
        <Col className="">
          <p className="main-subtitle pt-4">
            {testi[props.language].contract.title}
          </p>{" "}
          <p className="text-sm lg:text-xl">
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {testi[props.language].contract.text}
            </ReactMarkdown>
          </p>
        </Col>
        <Col className="">
          <p className="my-1">
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {testi[props.language].contract.textBlocks[0]}
            </ReactMarkdown>
          </p>
          <p className="my-1">
            {" "}
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {testi[props.language].contract.textBlocks[1]}
            </ReactMarkdown>
          </p>{" "}
          <p className="my-1">
            {" "}
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {testi[props.language].contract.textBlocks[2]}
            </ReactMarkdown>
          </p>{" "}
        </Col>
      </Row>
      {/*second row */}
      <Row className="flex flex-col md:flex-row">
        <Col className="md:mr-3">
          <p className="main-subtitle pb-4">
            {testi[props.language].contract.question}
          </p>{" "}
          <p className="text-sm lg:text-xl">
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {testi[props.language].contract.text2}
            </ReactMarkdown>
          </p>
          <div className="hidden md:block">
            <div className="grid-container ">
              <div>
                <a
                  className="nav-link text-center text-xl py-4"
                  href="https://calendly.com/onlytech_industries/30min?month=2024-03"
                  target="_blank"
                >
                  {testi[props.language].emailForm.download[0]}
                </a>
              </div>
              <div className=" ">
                <div
                  className="nav-link text-center text-xl py-4"
                  onClick={() => setBuyModal(true)}
                >
                  {testi[props.language].emailForm.download[1]}{" "}
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col className="flex flex-col justify-evenly">
          <p className="flex  main-subtitle pb-4">
            {testi[props.language].contract.advantages}
          </p>

          <div className="flex md:max-h-[125px]">
            {" "}
            <img
              src={blueCircle}
              className="h-[100px] xl:h-[100px] z-0 absolute"
            />
            <p className="  pl-5 lg:pl-5 text-sm lg:text-lg z-10">
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {testi[props.language].contract.textBlocks2[0]}
              </ReactMarkdown>
            </p>
          </div>

          <div className="flex md:max-h-[125px]">
            <img
              src={redCircle}
              className="h-[100px] xl:h-[100px] z-0 absolute"
            />
            <p className="  pl-5 lg:pl-5 text-sm lg:text-lg z-10">
              {" "}
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {testi[props.language].contract.textBlocks2[1]}
              </ReactMarkdown>
            </p>{" "}
          </div>
          <div className="flex md:max-h-[125px]">
            {" "}
            <img
              src={orangeCircle}
              className="h-[100px] xl:h-[100px] z-0 absolute"
            />
            <p className="  pl-5 lg:pl-5 text-sm lg:text-lg z-10">
              {" "}
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {testi[props.language].contract.textBlocks2[2]}
              </ReactMarkdown>
            </p>{" "}
          </div>
        </Col>
      </Row>
      <div className="block md:hidden ">
        <div className="grid-container ">
          <div>
            <a
              className="nav-link text-center text-xl py-4"
              href="https://calendly.com/onlytech_industries/30min?month=2024-03"
              target="_blank"
            >
              {testi[props.language].emailForm.download[0]}
            </a>
          </div>
          <div className=" ">
            <div
              className="nav-link text-center text-xl py-4"
              onClick={() => setBuyModal(true)}
            >
              {testi[props.language].emailForm.download[1]}
            </div>
          </div>
        </div>
      </div>
      <SimpleModal
        isOpen={buyModal}
        onClose={() => setBuyModal(false)}
        title={`Buy`}
        closeText={"cancel"}
        lightMode={props.lightMode}
        setPrivacyPolicy={props.setPrivacyPolicy}
      >
        <EmailModal
          language={props.language}
          onClose={props.onClose}
          lightMode={props.lightMode}
          setPrivacyPolicy={props.setPrivacyPolicy}
        />
      </SimpleModal>
    </>
  );
};
export default ContractSubcontent;
