import React from "react";
import { motion as m } from "framer-motion";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { Linkedin } from "react-bootstrap-icons";
import Navigation from "../components/Navigation";
import omxLogo from "../media/omx-logo.webp";
import testi from "../data/testi.json";
import Privacy from "../components/Privacy";
import MobileFooter from "../components/MobileFooter";
import poapLogo from "../media/icona-poapup.webp";
import astrolight from "../media/astro-light.webp";
import astrodark from "../media/astro-dark.webp";
import { useEffect } from "react";
import mockup from "../media/mockup.png";
const Popupevents = (props) => {
  return (
    <div className="App dark">
      <div className="Poapup">
        <div className="h-full">
          <div className="flex flex-col md:flex-row xxs:m-2 md:m-[20px] md:min-h-screen gap-4">
            {" "}
            <div className="flex w-full h-auto">
              {" "}
              <div className="flex flex-col justify-between w-full">
                <m.div className="nav mb-3">
                  <m.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    className="d-flex justify-content-center align-items-center w-100"
                  >
                    <Navigation
                      omx="true"
                      language={props.language}
                      setLanguage={props.setLanguage}
                    />
                  </m.div>
                </m.div>
                <m.div className="flex my-auto items-center">
                  <m.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    className="text-center"
                  >
                    <a
                      href="https://popupevents.xyz/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className=" mx-auto my-auto w-1/2 md:w-1/4 "
                        src={poapLogo}
                        alt="onlymusix logo shadowlogo "
                      />
                      <img className="mx-auto w-1/3 my-3 " src={mockup}></img>
                      <Button className="btn-dark mb-6 bg-white text-black ">
                        {testi[props.language].poapup.buttons[1]}
                      </Button>
                    </a>
                  </m.div>
                </m.div>
              </div>
            </div>
            <div className="flex w-full">
              <m.div
                className="main h-fit"
                initial={{ height: "0%", display: "none" }}
                animate={{ height: "100%", display: "flex" }}
                transition={{ duration: 1 }}
              >
                <m.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 0.6 }}
                  className="h-inherit d-flex flex-column justify-content-between"
                >
                  <div>
                    <p className="subtitle text-xl  lg:text-[45px] xl:text-[65px] xxl:text-[90px]">
                      {testi[props.language].poapup.title}
                    </p>
                    <p className="xxs:text-[15px] xl:text-[20px] xxxl:text-[30px]">
                      {testi[props.language].poapup.subtitles[0]}
                    </p>
                    <p className="xxs:text-[15px] xl:text-[20px] xxxl:text-[30px]">
                      {testi[props.language].poapup.testi[0]}
                    </p>
                  </div>
                  <div class=" grid-container-pop h-full">
                    <div class="services-link">
                      <p className="subtitle text-xl xxl:text-[30px]">
                        {testi[props.language].poapup.subtitles[1]}
                      </p>
                      <p className="main-text text-xs xl:text-base xxl:text-xl xxxl:text-[30px]">
                        {testi[props.language].poapup.testi[1]}
                      </p>
                    </div>

                    <div class="news-link">
                      <p className="subtitle text-xl xxl:text-[30px]">
                        {" "}
                        {testi[props.language].poapup.subtitles[2]}
                      </p>
                      <p className="main-text text-xs xl:text-base xxl:text-xl xxxl:text-[30px]">
                        {testi[props.language].poapup.testi[2]}
                      </p>
                    </div>

                    <div class="onlymusix-link d-flex">
                      <p className="subtitle text-xl xxl:text-[30px] me-auto my-auto">
                        {" "}
                        {testi[props.language].poapup.testi[3]}
                      </p>
                      <Link to="/" className=" m-auto">
                        <Button className="btn-light bg-none m-0">
                          {testi[props.language].poapup.buttons[0]}
                        </Button>
                      </Link>
                    </div>
                  </div>
                </m.div>
              </m.div>
              <m.div
                className="secondary d-none"
                initial={{ height: "100%" }}
                animate={{ height: "0%" }}
                transition={{ duration: 1 }}
              >
                <m.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                >
                  content
                </m.div>
              </m.div>
            </div>
            <div className="flex lg:hidden">
              <MobileFooter
                lightMode={"App dark"}
                language={props.language}
                setPrivacyPolicy={props.setPrivacyPolicy}
              />
            </div>
          </div>
          <m.div className="footer hidden lg:block d-none d-md-block">
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.9 }}
              className="mx-5 d-flex justify-content-between align-items-center h-100"
            >
              <span className="">
                Onlytech Industries S.r.l. Innovativa 2024
              </span>
              <span
                onClick={() => props.setPrivacyPolicy(true)}
                className="privacy-link"
              >
                {testi[props.language].footer[0]}
              </span>

              <span className="">
                <a
                  href="https://www.linkedin.com/company/onlytech-industries/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Linkedin className={"App dark"} />
                </a>
              </span>
              <span className="">P.Iva: 04346880240</span>
            </m.div>
          </m.div>
          <Privacy
            privacyPolicy={props.privacyPolicy}
            setPrivacyPolicy={props.setPrivacyPolicy}
            language={props.language}
          />
        </div>
      </div>
    </div>
  );
};

export default Popupevents;
