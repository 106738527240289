import React from "react";
import { motion as m } from "framer-motion";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import Navigation from "../components/Navigation";
import { Linkedin } from "react-bootstrap-icons";
import errorlight from "../media/error-light.webp";
import errordark from "../media/error-dark.webp";
import testi from "../data/testi.json";
import Privacy from "../components/Privacy";
import MobileFooter from "../components/MobileFooter";

const Error404 = (props) => {
  return (
    <div className="Home">
      <Row xs={1} md={2}>
        <Col className="d-flex flex-column justify-content-between">
          <m.div className="nav">
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="d-flex justify-content-center align-items-center w-100"
            >
              <Navigation
                setLightMode={props.setLightMode}
                lightMode={props.lightMode}
                language={props.language}
                setLanguage={props.setLanguage}
              />
            </m.div>
          </m.div>
          <m.div className="primary">
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.6 }}
              className="d-flex flex-column align-items-center"
            >
              <img
                src={props.lightMode === "App light" ? errorlight : errordark}
                alt="astronauta onlytech industries che fluttua"
              />
            </m.div>
          </m.div>
          <m.div className="footer d-none d-md-block">
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.9 }}
              className="mx-5 d-flex justify-content-between align-items-center h-100"
            >
              <span className="">
                Onlytech Industries S.r.l. Innovativa 2024
              </span>
              <span
                onClick={() => props.setPrivacyPolicy(true)}
                className="privacy-link"
              >
                {testi[props.language].footer[0]}
              </span>

              <span className="">
                <a
                  href="https://www.linkedin.com/company/onlytech-industries/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Linkedin className={props.lightMode} />
                </a>
              </span>
              <span className="">P.Iva: 04346880240</span>
            </m.div>
          </m.div>
        </Col>
        <Col>
          <m.div
            className="main"
            initial={{ height: "30%" }}
            animate={{ height: "100%" }}
            transition={{ duration: 1 }}
          >
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.6 }}
              className="h-100 d-flex flex-column "
            >
              <div>
                <p className="main-title">
                  {testi[props.language].error404.title}
                </p>
                <p className="main-subtitle">
                  {testi[props.language].error404.subtitles[0]}
                </p>
              </div>
              <Link to="/">
                <Button className="btn mt-4">
                  {testi[props.language].error404.buttons[0]}
                </Button>
              </Link>
            </m.div>
          </m.div>
          <m.div
            className="secondary d-none"
            initial={{ height: "100%" }}
            animate={{ height: "0%" }}
            transition={{ duration: 1 }}
          >
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              content
            </m.div>
          </m.div>

          <MobileFooter
            lightMode={props.lightMode}
            language={props.language}
            setPrivacyPolicy={props.setPrivacyPolicy}
          />
        </Col>
      </Row>
      <Privacy
        privacyPolicy={props.privacyPolicy}
        setPrivacyPolicy={props.setPrivacyPolicy}
        language={props.language}
      />
    </div>
  );
};

export default Error404;
