import React from "react";
import { motion as m } from "framer-motion";
import { Row, Col, Tab, Nav } from "react-bootstrap";
import { Linkedin } from "react-bootstrap-icons";
import Navigation from "../components/Navigation";

import testi from "../data/testi.json";
import Privacy from "../components/Privacy";
import MobileFooter from "../components/MobileFooter";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import "tailwindcss/tailwind.css";
import "../style/App.scss";

import blueCircle from "../media/OmxBlueCircle.png";
import orangeCircle from "../media/omxOrangeCircle.png";
import redCircle from "../media/OmxRedCircle.png";
import nino from "../media/ninoCube.png";
import silvia from "../media/silviaCube.png";
import michele from "../media/micheleCube.png";
import Partner from "./Partners";
import { Helmet } from "react-helmet-async";
const AboutUs = (props) => {
  const title = "Onlytech Industries" + " | " + testi[props.language].navbar[4];
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://www.onlytechindustries.com/static/media/rocket-light.21d805a7e0a14bfa17ab.webp"
        />
        <meta
          name="og:description"
          content="Onlytech Industries: Innovative blockchain solutions for businesses."
        />
        <meta
          property="twitter:image"
          content="https://www.onlytechindustries.com/static/media/rocket-light.21d805a7e0a14bfa17ab.webp"
        />
        <meta property="twitter:title" content={title} />
        <meta
          property="twitter:description"
          content="Onlytech Industries: Innovative blockchain solutions for businesses."
        />
      </Helmet>
      <div className="Services">
        <Tab.Container defaultActiveKey="blockchain">
          <Row xs={1} md={2} className="px-[20px]">
            <Col className="">
              <div className="nav">
                <m.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                  className="d-flex justify-content-center align-items-center w-100"
                >
                  <Navigation
                    setLightMode={props.setLightMode}
                    lightMode={props.lightMode}
                    language={props.language}
                    setLanguage={props.setLanguage}
                  />
                </m.div>
              </div>
              <m.div
                className="main my-3 text-xl md:hidden lg:mb-6"
                initial={{ height: "" }}
                animate={{ height: "" }}
                transition={{ duration: 1 }}
              >
                <m.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                >
                  <p className="main-title my-2">
                    {testi[props.language].navbar[4]}
                  </p>
                </m.div>
              </m.div>
              <a name="primary-services" />
              <div>
                <p className="subtitle2 z-10 lg:my-5 pl-2 lg:pl-5 text-[30px] xl:text-[60px]">
                  {testi[props.language].about.title}
                </p>
                <p className="my-1 pl-2 lg:pl-5 text-sm lg:text-lg">
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {testi[props.language].about.text}
                  </ReactMarkdown>
                </p>
              </div>
            </Col>
            <Col>
              <div className=" hidden md:block md:mb-4">
                <m.div
                  className="main"
                  initial={{ height: "" }}
                  animate={{ height: "" }}
                  transition={{ duration: 1 }}
                >
                  <m.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                  >
                    <p className="subtitle2 md:text-[60px] lg:text-[80px] xl:text-[90px] my-auto">
                      {testi[props.language].navbar[4]}
                    </p>
                  </m.div>
                </m.div>
              </div>

              <Col className="">
                <div className="xl:mb-6 min-h-[120px]">
                  <img
                    src={blueCircle}
                    className="h-[100px] xl:h-[125px] absolute z-0 "
                  />
                  <p className="my-1 pl-5 lg:pl-5 relative text-sm lg:text-lg">
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                      {testi[props.language].about.blocks[0]}
                    </ReactMarkdown>
                  </p>
                </div>
                <div className="xl:mb-6 min-h-[120px]">
                  <img
                    src={orangeCircle}
                    className="h-[100px]  xl:h-[125px] absolute z-0  "
                  />
                  <p className=" pl-5 lg:pl-5 my-1 relative text-sm lg:text-lg">
                    {" "}
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                      {testi[props.language].about.blocks[1]}
                    </ReactMarkdown>
                  </p>{" "}
                </div>

                <div className="xl:mb-6 min-h-[120px]">
                  <img
                    src={redCircle}
                    className="h-[100px] xl:h-[125px] absolute z-0  "
                  />
                  <p className="my-1 pl-5 lg:pl-5 relative text-sm lg:text-lg">
                    {" "}
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                      {testi[props.language].about.blocks[2]}
                    </ReactMarkdown>
                  </p>{" "}
                </div>
              </Col>
            </Col>
          </Row>

          {/*sub content */}
          <p className="subtitle2 xl:my-5 pl-5 mt-4 lg:pl-5 text-[30px] xl:text-[60px]">
            {" "}
            {testi[props.language].about.team}
          </p>
          <div className="max-w-1440 mx-auto">
            <div className="flex flex-col  md:flex-row lg:justify-around xl:my-8 xxl:my-7 max-w-1440">
              <div className="flex flex-col mb-3">
                <img
                  className="mx-auto lg:relative w-[150px] h-[150px]  xl:w-[300px] xl:h-[300px] lg:left-[35px]"
                  src={nino}
                />

                <span className="subtitle text-base md:text-xl py-2 mt-2 text-center">
                  <a
                    className="textLink"
                    href="https://www.linkedin.com/in/antoninoabbate/"
                  >
                    Antonino Abbate
                  </a>
                </span>
                <span className="subtitle text-center text-xs md:text-base">
                  Chief Executive Officer and Founder
                </span>
              </div>
              <div className="flex flex-col mb-3">
                <img
                  className="mx-auto lg:relative w-[150px] h-[150px]  xl:w-[300px] xl:h-[300px] lg:left-[35px]"
                  src={silvia}
                />

                <span className="subtitle text-base md:text-xl py-2 mt-2 text-center">
                  <a
                    className="textLink"
                    href="https://www.linkedin.com/in/silvia-bertelli/"
                  >
                    Silvia Bertelli
                  </a>
                </span>
                <span className="subtitle text-center text-xs md:text-base">
                  Chief of Marketing and Operation Officer
                </span>
              </div>
              <div className="flex flex-col mb-3">
                <img
                  className="mx-auto lg:relative w-[150px] h-[150px]  xl:w-[300px] xl:h-[300px] lg:left-[35px]"
                  src={michele}
                />

                <span className="subtitle text-base md:text-xl py-2 mt-2 text-center">
                  Michele Marchetto
                </span>
                <span className="subtitle text-center text-xs md:text-base">
                  Chief Technical Officer and Hero
                </span>
              </div>
            </div>
          </div>
          <p className="subtitle2 xl:my-5 pl-5 mt-4 lg:pl-5 text-[30px] xl:text-[60px]">
            {" "}
            {testi[props.language].about.partners}
          </p>
          <div className="pl-5 pr-5 mb-5 max-w-1440 mx-auto">
            <Partner lightMode={props.lightMode} />
            <MobileFooter
              lightMode={props.lightMode}
              language={props.language}
              setPrivacyPolicy={props.setPrivacyPolicy}
            />
          </div>

          <m.div className="footer d-none d-md-block">
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.9 }}
              className="mx-5 d-flex justify-content-between align-items-center h-100"
            >
              <span className="">
                Onlytech Industries S.r.l. Innovativa 2024
              </span>
              <span
                onClick={() => props.setPrivacyPolicy(true)}
                className="privacy-link"
              >
                {testi[props.language].footer[0]}
              </span>

              <span className="">
                <a
                  href="https://www.linkedin.com/company/onlytech-industries/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Linkedin className={props.lightMode} />
                </a>
              </span>
              <span className="">P.Iva: 04346880240</span>
            </m.div>
          </m.div>
        </Tab.Container>
        <Privacy
          privacyPolicy={props.privacyPolicy}
          setPrivacyPolicy={props.setPrivacyPolicy}
          language={props.language}
        />
      </div>
    </>
  );
};

export default AboutUs;
