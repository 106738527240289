import React from "react";
import { motion as m } from "framer-motion";
import { Row, Col, Tab, Nav } from "react-bootstrap";
import { Linkedin } from "react-bootstrap-icons";
import Navigation from "../components/Navigation";
import rocketlight from "../media/rocket-light.webp";
import rocketdark from "../media/rocket-dark.webp";
import smartcontractlight from "../media/smart-contract-light.webp";
import smartcontractdark from "../media/smart-contract-dark.webp";
import dapplight from "../media/dapp-light.webp";
import dappdark from "../media/dapp-dark.webp";
import consulenzalight from "../media/consulenza-light.webp";
import consulenzadark from "../media/consulenza-dark.webp";
import certificationlight from "../media/certification-light.webp";
import certificationdark from "../media/certification-dark.webp";
import testi from "../data/testi.json";
import Privacy from "../components/Privacy";
import MobileFooter from "../components/MobileFooter";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import "tailwindcss/tailwind.css";
import "../style/App.scss";
import BlockchainSubcontent from "./BlockchainSubcontent";
import DecentralizedSubcontent from "./DecentralizedSubcontent";
import CertificationSubcontent from "./CertificationSubcontent";
import ContractSubcontent from "./ContractSubcontent";
import ConsultancySubcontent from "./ConsultancySubcontent";
import { SimpleModal } from "./SimpleModal";
import { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
const Services = (props) => {
  const [checkboxError, setCheckboxError] = useState(false);
  const title = "Onlytech Industries" + " | " + testi[props.language].navbar[1];
  const [buyModal, setBuyModal] = useState(false);
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://www.onlytechindustries.com/static/media/ServiceThumb.6c2c5d815cb7a8b800c1.png"
        />
        <meta
          property="og:description"
          content="Onlytech Industries: Innovative blockchain solutions for businesses."
        />
        <meta
          property="twitter:image"
          content="https://www.onlytechindustries.com/static/media/ServiceThumb.6c2c5d815cb7a8b800c1.png"
        />
        <meta property="twitter:title" content={title} />
        <meta
          property="twitter:description"
          content="Onlytech Industries: Innovative blockchain solutions for businesses."
        />
      </Helmet>
      <div className="Services">
        <Tab.Container defaultActiveKey="blockchain">
          <Row xs={1} md={2} className="px-[20px]">
            <Col className="">
              <div className="nav">
                <m.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                  className="d-flex justify-content-center align-items-center w-100"
                >
                  <Navigation
                    setLightMode={props.setLightMode}
                    lightMode={props.lightMode}
                    language={props.language}
                    setLanguage={props.setLanguage}
                  />
                </m.div>
              </div>
              <m.div
                className="main my-2 md:hidden lg:mb-6"
                initial={{ height: "100%" }}
                animate={{ height: "40%" }}
                transition={{ duration: 1 }}
              >
                <m.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                >
                  <p className="main-title">
                    {testi[props.language].servizi.title}
                  </p>
                  <p className="mt-4 main-subtitle">
                    {testi[props.language].servizi.subtitles[0]}
                  </p>
                </m.div>
              </m.div>
              <a name="primary-services" />
              <m.div
                className="m-4  z-0"
                initial={{ height: "0%", display: "none" }}
                animate={{ height: "100%", display: "flex" }}
                transition={{ duration: 1 }}
              >
                <m.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 0.6 }}
                >
                  <Tab.Content>
                    <Tab.Pane eventKey="blockchain">
                      <p className="main-subtitle z-10">
                        {testi[props.language].servizi.subtitles[1]}
                      </p>
                      <p className="text-xxl mt-4">
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                          {testi[props.language].blockchain.subtitle}
                        </ReactMarkdown>
                      </p>

                      <div className="mt-5 text-center mx-auto">
                        <img
                          className="w-100"
                          src={
                            props.lightMode === "App light"
                              ? rocketlight
                              : rocketdark
                          }
                          alt="razzo onlytech industries che fluttua"
                        />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="contract">
                      <p className="main-subtitle z-10">
                        {testi[props.language].contract.title}
                      </p>
                      <p className="text-xxl mt-4">
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                          {testi[props.language].contract.subtitle}
                        </ReactMarkdown>
                      </p>
                      <div className="text-center">
                        <img
                          src={
                            props.lightMode === "App light"
                              ? smartcontractlight
                              : smartcontractdark
                          }
                          alt="sezione smart contracts"
                        />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="token">
                      <p className="main-subtitle z-10">
                        {testi[props.language].certification.title}
                      </p>
                      <p className="text-xxl mt-4">
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                          {testi[props.language].certification.subtitle}
                        </ReactMarkdown>
                      </p>
                      <div className="text-center">
                        <img
                          src={
                            props.lightMode === "App light"
                              ? certificationlight
                              : certificationdark
                          }
                          alt="sezione certificazioni"
                        />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="decentralized">
                      <p className="main-subtitle z-10">
                        {testi[props.language].decentralized.title}
                      </p>
                      <p className="text-xxl mt-4">
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                          {testi[props.language].decentralized.subtitle}
                        </ReactMarkdown>
                      </p>
                      <div className="mt-5 text-center mx-auto">
                        <img
                          className="w-100"
                          src={
                            props.lightMode === "App light"
                              ? dapplight
                              : dappdark
                          }
                          alt="sezione dapp"
                        />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="consulting">
                      <p className="main-subtitle z-10">
                        {testi[props.language].consulting.title}
                      </p>
                      <p className="text-xxl mt-4">
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                          {testi[props.language].consulting.subtitle}
                        </ReactMarkdown>
                      </p>
                      <div className="text-center">
                        <img
                          src={
                            props.lightMode === "App light"
                              ? consulenzalight
                              : consulenzadark
                          }
                          alt="sezione consulenza"
                        />
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </m.div>
              </m.div>
            </Col>
            <Col>
              <m.div
                className="main hidden md:block lg:mb-[20px]"
                initial={{ height: "100%" }}
                animate={{ height: "40%" }}
                transition={{ duration: 1 }}
              >
                <m.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                >
                  <p className="subtitle text-[50px] lg:text-[96px]">
                    {testi[props.language].servizi.title}
                  </p>
                  <p className="mt-4 subtitle lg:text-[25px] xl:text-[35px]">
                    {testi[props.language].servizi.subtitles[0]}
                  </p>
                </m.div>
              </m.div>
              <div className="hidden md:flex h-full ">
                <m.div
                  className="secondary3"
                  initial={{ height: "0%", display: "none" }}
                  animate={{ height: "100%", display: "flex" }}
                  transition={{ duration: 1, delay: 0.3 }}
                >
                  <div className="grid-container w-full min-h-full md:my-1 lg:my-auto">
                    <div class="blockchain">
                      <Nav.Link eventKey="blockchain" href="#blockchain">
                        <p className="subtitle  md:text-[16px] lg:text-[25px] xl:text-[35px] xxl:text-[45px] xxxl:text-[69px] ">
                          {testi[props.language].servizi.subtitles[1]}
                        </p>
                      </Nav.Link>
                    </div>

                    <div class="contract">
                      <Nav.Link eventKey="contract" href="#contract">
                        <p className="subtitle  md:text-[16px] lg:text-[25px] xl:text-[35px] xxl:text-[45px] xxxl:text-[69px] m-auto">
                          {testi[props.language].servizi.subtitles[4]}
                        </p>
                      </Nav.Link>
                    </div>
                    <div class="token">
                      <Nav.Link eventKey="token" href="#token">
                        <p className="subtitle  md:text-[16px] lg:text-[25px] xl:text-[35px] xxl:text-[45px] xxxl:text-[69px] m-auto">
                          {testi[props.language].servizi.subtitles[3]}
                        </p>
                      </Nav.Link>
                    </div>
                    <div class="decentralized">
                      <Nav.Link eventKey="decentralized" href="#decentralized">
                        <p className="subtitle  md:text-[16px] lg:text-[25px] xl:text-[35px] xxl:text-[45px] xxxl:text-[69px] m-auto">
                          {testi[props.language].servizi.subtitles[5]}
                        </p>
                      </Nav.Link>
                    </div>
                    <div className="consulting">
                      <Nav.Link
                        eventKey="consulting"
                        href="#consulting"
                        className=""
                      >
                        <p className="subtitle  md:text-[16px] lg:text-[25px] xl:text-[35px] xxl:text-[45px] xxxl:text-[69px] m-auto">
                          {testi[props.language].servizi.subtitles[2]}
                        </p>
                      </Nav.Link>
                    </div>
                  </div>
                </m.div>
              </div>
            </Col>
          </Row>

          <m.div
            className="primary mx-3 p-4"
            initial={{ height: "0%", display: "none" }}
            animate={{ height: "100%", display: "flex" }}
            transition={{ duration: 1 }}
          >
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.6 }}
            >
              {/*sub content */}

              <Tab.Content>
                <Tab.Pane eventKey="blockchain">
                  <BlockchainSubcontent
                    language={props.language}
                    lightMode={props.lightMode}
                    setPrivacyPolicy={props.setPrivacyPolicy}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="contract">
                  <ContractSubcontent
                    language={props.language}
                    lightMode={props.lightMode}
                    setPrivacyPolicy={props.setPrivacyPolicy}
                  />
                </Tab.Pane>

                <Tab.Pane eventKey="token">
                  <CertificationSubcontent
                    language={props.language}
                    lightMode={props.lightMode}
                    setPrivacyPolicy={props.setPrivacyPolicy}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="decentralized">
                  <DecentralizedSubcontent
                    language={props.language}
                    lightMode={props.lightMode}
                    setPrivacyPolicy={props.setPrivacyPolicy}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="consulting">
                  <ConsultancySubcontent
                    language={props.language}
                    lightMode={props.lightMode}
                    setPrivacyPolicy={props.setPrivacyPolicy}
                  />
                </Tab.Pane>
              </Tab.Content>
            </m.div>
          </m.div>
          <div className=" md:hidden mx-[20px]">
            <m.div
              className="secondary"
              initial={{ height: "0%", display: "none" }}
              animate={{ height: "100%", display: "flex" }}
              transition={{ duration: 1, delay: 0.3 }}
            >
              <div className="grid-container w-full ">
                <div class="blockchain">
                  <Nav.Link eventKey="blockchain" href="#blockchain">
                    <p className="subtitle  md:text-[16px] lg:text-[25px] xl:text-[35px] xxl:text-[45px] xxxl:text-[69px] m-auto">
                      {testi[props.language].servizi.subtitles[1]}
                    </p>
                  </Nav.Link>
                </div>

                <div class="contract">
                  <Nav.Link eventKey="contract" href="#contract">
                    <p className="subtitle md:text-[16px] lg:text-[25px] xl:text-[35px] my-auto">
                      {testi[props.language].servizi.subtitles[4]}
                    </p>
                  </Nav.Link>
                </div>
                <div class="token">
                  <Nav.Link eventKey="token" href="#token">
                    <p className="subtitle  md:text-[16px] lg:text-[25px] xl:text-[35px] xxl:text-[45px] xxxl:text-[69px] m-auto">
                      {testi[props.language].servizi.subtitles[3]}
                    </p>
                  </Nav.Link>
                </div>
                <div class="decentralized">
                  <Nav.Link eventKey="decentralized" href="#decentralized">
                    <p className="subtitle  md:text-[16px] lg:text-[25px] xl:text-[35px] xxl:text-[45px] xxxl:text-[69px] m-auto">
                      {testi[props.language].servizi.subtitles[5]}
                    </p>
                  </Nav.Link>
                </div>
                <div className="consulting">
                  <Nav.Link
                    eventKey="consulting"
                    href="#consulting"
                    className=""
                  >
                    <p className="subtitle md:text-[16px] lg:text-[25px] xl:text-[35px] my-auto">
                      {testi[props.language].servizi.subtitles[2]}
                    </p>
                  </Nav.Link>
                </div>
              </div>
            </m.div>
          </div>
          <div className="mx-3">
            <MobileFooter
              lightMode={props.lightMode}
              language={props.language}
              setPrivacyPolicy={props.setPrivacyPolicy}
            />
          </div>

          <m.div className="footer d-none d-md-block mx-[16px]">
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.9 }}
              className="mx-5 d-flex justify-content-between align-items-center h-100"
            >
              <span className="">
                Onlytech Industries S.r.l. Innovativa 2024
              </span>
              <span
                onClick={() => props.setPrivacyPolicy(true)}
                className="privacy-link"
              >
                {testi[props.language].footer[0]}
              </span>

              <span className="">
                <a
                  href="https://www.linkedin.com/company/onlytech-industries/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Linkedin className={props.lightMode} />
                </a>
              </span>
              <span className="">P.Iva: 04346880240</span>
            </m.div>
          </m.div>
        </Tab.Container>
        <SimpleModal
          isOpen={buyModal}
          onClose={() => setBuyModal(false)}
          title={`Buy`}
          closeText={"cancel"}
        >
          <div className="h-[200px] main"></div>
        </SimpleModal>

        <Privacy
          privacyPolicy={props.privacyPolicy}
          setPrivacyPolicy={props.setPrivacyPolicy}
          language={props.language}
        />
      </div>
    </>
  );
};

export default Services;
