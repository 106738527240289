import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import { BrightnessLowFill } from "react-bootstrap-icons";
import logoLight from "../media/logo.svg";
import logoDark from "../media/logo-dark.svg";
import testi from "../data/testi.json";

function Navigation(props) {
  return (
    <Navbar
      collapseOnSelect
      expand="xxl"
      className={
        props.lightMode === "App light"
          ? "w-100 mx-1 "
          : "w-100 mx-1 navbar-dark"
      }
    >
      <Container>
        <Navbar.Brand href="#home" className="me-auto md:w-[70%] xl:w-fit">
          <Link to="/">
            <img
              className=""
              src={props.lightMode === "App light" ? logoLight : logoDark}
              alt="onlytech industries logo"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className=" ms-auto">
            <Link to="/">
              <Nav.Link href="#home">
                {testi[props.language].navbar[0]}
              </Nav.Link>
            </Link>
            <Link to="/services">
              <Nav.Link href="#services">
                {testi[props.language].navbar[1]}
              </Nav.Link>
            </Link>
            <Link to="/news">
              <Nav.Link href="#news">
                {testi[props.language].navbar[2]}
              </Nav.Link>
            </Link>
            <Link to="/about">
              <Nav.Link href="#about" className="">
                {testi[props.language].navbar[4]}
              </Nav.Link>
            </Link>
            <NavDropdown
              className="z-100"
              title={testi[props.language].navbar[3]}
              id="collasible-nav-dropdown"
            >
              <NavDropdown.Item
                href="mailto:info@onlytechindustries.com"
                target="_blank"
              >
                info@onlytechindustries.com
              </NavDropdown.Item>
              <NavDropdown.Item href="tel:+3904451888631">
                +39 0445 1888631
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link
              className="fw-bold"
              onClick={() => {
                props.language === "it"
                  ? props.setLanguage("en")
                  : props.setLanguage("it");
              }}
            >
              {props.language === "it" ? "en" : "it"}
            </Nav.Link>

            {props.omx ? (
              ""
            ) : (
              <Nav.Link href="#dark">
                <BrightnessLowFill
                  size={28}
                  onClick={() => {
                    props.lightMode === "App light"
                      ? props.setLightMode("App dark")
                      : props.setLightMode("App light");
                  }}
                />
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
