import React, { useState } from "react";
import { motion as m } from "framer-motion";
import { Row, Col } from "react-bootstrap";
import Navigation from "../components/Navigation";
import { Linkedin } from "react-bootstrap-icons";
import testi from "../data/testi.json";
import articles from "../data/news.json";
import Privacy from "../components/Privacy";
import MobileFooter from "../components/MobileFooter";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import NewsDisclaimer from "../components/NewsDisclaimer";
import { Helmet } from "react-helmet-async";
const News = (props) => {
  const [news, setNews] = useState(0);
  const title = "Onlytech Industries" + " | " + testi[props.language].navbar[2];
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://www.onlytechindustries.com/static/media/rocket-light.21d805a7e0a14bfa17ab.webp"
        />
        <meta
          name="og:description"
          content="Onlytech Industries: Innovative blockchain solutions for businesses."
        />
        <meta
          property="twitter:image"
          content="https://www.onlytechindustries.com/static/media/rocket-light.21d805a7e0a14bfa17ab.webp"
        />
        <meta property="twitter:title" content={title} />
        <meta
          property="twitter:description"
          content="Onlytech Industries: Innovative blockchain solutions for businesses."
        />
      </Helmet>

      <m.div className="News mx-[20px]">
        <Row xs={1} md={2} className="">
          <Col>
            <div className="nav mb-3">
              <m.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                className="d-flex justify-content-center align-items-center w-100 "
              >
                <Navigation
                  setLightMode={props.setLightMode}
                  lightMode={props.lightMode}
                  language={props.language}
                  setLanguage={props.setLanguage}
                />
              </m.div>
            </div>

            <m.div
              className="primary d-none d-md-block"
              initial={{ height: "0%", display: "none" }}
              animate={{ height: "100%", display: "block" }}
              transition={{ duration: 1 }}
            >
              <m.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.6 }}
              >
                <p className="main-subtitle">
                  {articles[props.language].articles[news].titolo}
                </p>
                <div className="flex my-5 overflow-hidden">
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {articles[props.language].articles[news].immagine}
                  </ReactMarkdown>
                </div>
                <p className="main-text px-[19px]" style={{ fontSize: "14px" }}>
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {articles[props.language].articles[news].testo[0]}
                  </ReactMarkdown>
                </p>
              </m.div>
            </m.div>
          </Col>
          <Col>
            <m.div
              className="main"
              initial={{ height: "100%" }}
              animate={{ height: "40%" }}
              transition={{ duration: 1 }}
            >
              <m.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
              >
                <p className="main-title">{testi[props.language].news.title}</p>

                <p className="mt-4 main-subtitle">
                  {testi[props.language].news.subtitles[0]}
                </p>
              </m.div>
            </m.div>
            {/*
            
                        
          */}
            <a name="primary-mobile" />
            <m.div
              className="primary d-block d-md-none"
              initial={{ height: "0%", display: "none" }}
              animate={{ height: "100%", display: "block" }}
              transition={{ duration: 1 }}
            >
              <m.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.6 }}
              >
                <p className="main-subtitle">
                  {articles[props.language].articles[news].titolo}
                </p>
                <div className="flex my-3 overflow-hidden">
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {articles[props.language].articles[news].immagine}
                  </ReactMarkdown>
                </div>
                <p className="main-text" style={{ fontSize: "14px" }}>
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {articles[props.language].articles[news].testo[0]}
                  </ReactMarkdown>
                </p>
              </m.div>
            </m.div>
            {articles[props.language].articles.map((item, i) => (
              <NewsDisclaimer
                language={props.language}
                setNews={setNews}
                article={item}
                index={i}
              />
            ))}

            <MobileFooter
              lightMode={props.lightMode}
              language={props.language}
              setPrivacyPolicy={props.setPrivacyPolicy}
            />
          </Col>
        </Row>
        <m.div className="footer d-none d-md-block">
          <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.9 }}
            className="mx-5 d-flex justify-content-between align-items-center h-100"
          >
            <span className="">Onlytech Industries S.r.l. Innovativa 2024</span>
            <span
              onClick={() => props.setPrivacyPolicy(true)}
              className="privacy-link"
            >
              {testi[props.language].footer[0]}
            </span>

            <span className="">
              <a
                href="https://www.linkedin.com/company/onlytech-industries/"
                target="_blank"
                rel="noreferrer"
              >
                <Linkedin className={props.lightMode} />
              </a>
            </span>
            <span className="">P.Iva: 04346880240</span>
          </m.div>
        </m.div>
        <Privacy
          privacyPolicy={props.privacyPolicy}
          setPrivacyPolicy={props.setPrivacyPolicy}
          language={props.language}
        />
      </m.div>
    </>
  );
};

export default News;
