import React from "react";
import { motion as m } from "framer-motion";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import Navigation from "../components/Navigation";
import { Linkedin } from "react-bootstrap-icons";
import astrolight from "../media/astro-light.webp";
import astrodark from "../media/astro-dark.webp";
import testi from "../data/testi.json";
import Privacy from "../components/Privacy";
import MobileFooter from "../components/MobileFooter";
import { Helmet } from "react-helmet-async";
import logohome from "../media/logoHome.webp";
const Home = (props) => {
  const { lightMode, language } = props;
  const title = "Onlytech Industries" + " | " + testi[props.language].navbar[0];
  const description =
    "Onlytech Industries: Innovative blockchain solutions for businesses.";
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://www.onlytechindustries.com/static/media/logoHome.f89cbed6de209a8dffbc.png"
        />
        <meta
          name="og:description"
          content="Onlytech Industries: Innovative blockchain solutions for businesses."
        />
        <meta
          property="twitter:image"
          content="https://www.onlytechindustries.com/static/media/logoHome.f89cbed6de209a8dffbc.png"
        />
        <meta property="twitter:title" content={title} />
        <meta
          property="twitter:description"
          content="Onlytech Industries: Innovative blockchain solutions for businesses."
        />
      </Helmet>
      <div className="Home 	">
        <div className="h-full">
          <div className="flex flex-col md:flex-row xxs:m-2 md:m-[20px] md:min-h-screen gap-4">
            <div className="flex w-full h-auto">
              <div className="flex flex-col">
                <m.div className="nav mb-3">
                  <m.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    className="flex my-auto justify-content-center align-items-center w-100"
                  >
                    <Navigation
                      setLightMode={props.setLightMode}
                      lightMode={props.lightMode}
                      language={props.language}
                      setLanguage={props.setLanguage}
                    />
                  </m.div>
                </m.div>
                <m.div className="my-auto">
                  <m.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.6 }}
                    className="flex flex-col my-auto items-center"
                  >
                    <m.div
                      animate={{ y: -20 }}
                      transition={{
                        ease: "linear",
                        duration: 2,
                        repeat: Infinity,
                        repeatType: "reverse",
                      }}
                      className="d-flex my-auto "
                    >
                      <img
                        className="mx-auto mt-3 w-fit "
                        src={
                          props.lightMode === "App light"
                            ? astrolight
                            : astrodark
                        }
                        alt="astronauta onlytech industries che fluttua"
                      />
                    </m.div>
                    <p className="flex main-subtitle text-center mt-3">
                      {testi[props.language].home.subtitles[2]}
                    </p>
                  </m.div>
                </m.div>
              </div>
            </div>
            <div className="flex w-full">
              <m.div
                className="main h-fit"
                initial={{ height: "0%", display: "none" }}
                animate={{ height: "100%", display: "flex" }}
                transition={{ duration: 1 }}
              >
                <m.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 0.6 }}
                  className="h-inherit d-flex flex-column justify-content-between"
                >
                  <div>
                    <p className="subtitle text-xl  md:text-[45px] xl:text-[65px] xxl:text-[90px]">
                      {testi[props.language].home.title}
                    </p>
                    <p className="text-[15px] xxl:text-[20px] xxxl:text-[30px]">
                      {testi[props.language].home.testi[0]}
                    </p>
                  </div>
                  <div class=" grid-container h-full">
                    <div class="services-link">
                      <p className="subtitle text-xl xxl:text-[30px]">
                        {testi[props.language].home.subtitles[0]}
                      </p>
                      <p className="main-text text-xs xl:text-base xxl:text-xl xxxl:text-[30px]">
                        {testi[props.language].home.testi[1]}
                      </p>
                      <Link to="/services">
                        <Button className="btn">
                          {testi[props.language].home.buttons[0]}
                        </Button>
                      </Link>
                    </div>

                    <div class="news-link">
                      <p className="subtitle text-xl xxl:text-[30px]">
                        {testi[props.language].home.subtitles[1]}
                      </p>
                      <p className="main-text text-xs xl:text-base xxl:text-xl xxxl:text-[30px]">
                        {testi[props.language].home.testi[2]}
                      </p>

                      <Link to="/news">
                        <Button className="btn">
                          {testi[props.language].home.buttons[1]}
                        </Button>
                      </Link>
                    </div>
                    <div class="onlymusix-link d-flex">
                      {/* <img src={omxReduced} style={{ maxHeight: "40px" }} /> */}
                      <p className="subtitle text-xl xxl:text-[30px] me-auto my-auto">
                        {testi[props.language].home.testi[3]}
                      </p>
                      <Link to="/onlymusix" className="my-auto">
                        <Button className="btn">
                          {testi[props.language].home.buttons[2]}
                        </Button>
                      </Link>
                    </div>
                    <div class="poap-link d-flex">
                      {/* <img src={omxReduced} style={{ maxHeight: "40px" }} /> */}
                      <p className="subtitle text-xl xxl:text-[30px] me-auto my-auto">
                        {testi[props.language].home.testi[4]}
                      </p>
                      <Link to="/popupevents" className="my-auto">
                        <Button className="btn">
                          {testi[props.language].home.buttons[2]}
                        </Button>
                      </Link>
                    </div>
                    <div class="onlymusix-link d-flex">
                      {/* <img src={omxReduced} style={{ maxHeight: "40px" }} /> */}
                      <p className="subtitle text-xl xxl:text-[30px] me-auto my-auto">
                        {testi[props.language].home.testi[3]}
                      </p>
                      <Link to="/onlymusix" className="my-auto">
                        <Button className="btn">
                          {testi[props.language].home.buttons[2]}
                        </Button>
                      </Link>
                    </div>
                  </div>
                </m.div>
              </m.div>
              <m.div
                className="secondary d-none"
                initial={{ height: "100%" }}
                animate={{ height: "0%" }}
                transition={{ duration: 1 }}
              >
                <m.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                >
                  content
                </m.div>
              </m.div>
            </div>
            <div className="flex md:hidden">
              <MobileFooter
                lightMode={props.lightMode}
                language={props.language}
                setPrivacyPolicy={props.setPrivacyPolicy}
              />
            </div>
          </div>
          {/* footer */}
          <m.div className="footer hidden md:block d-none d-md-block">
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.9 }}
              className="mx-5 d-flex justify-content-between align-items-center h-100"
            >
              <span className="">
                Onlytech Industries S.r.l. Innovativa 2024
              </span>
              <span
                onClick={() => props.setPrivacyPolicy(true)}
                className="privacy-link"
              >
                {testi[props.language].footer[0]}
              </span>

              <span className="">
                <a
                  href="https://www.linkedin.com/company/onlytech-industries/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Linkedin className={props.lightMode} />
                </a>
              </span>
              <span className="">P.Iva: 04346880240</span>
            </m.div>
          </m.div>
          <Privacy
            privacyPolicy={props.privacyPolicy}
            setPrivacyPolicy={props.setPrivacyPolicy}
            language={props.language}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
