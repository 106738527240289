import React from "react";

import { motion as m } from "framer-motion";
import testi from "../data/testi.json";

import { Row, Col } from "react-bootstrap";

import { Linkedin } from "react-bootstrap-icons";
import logoLight from "../media/logo.svg";
import logoDark from "../media/logo-dark.svg";

const MobileFooter = (props) => {
  return (
    <div className="footer d-block d-md-none">
      <m.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.9 }}
        className="mx-5 d-flex justify-content-between align-items-center h-100"
      >
        <Row xs={1} md={4}>
          <Col>
            <img
              src={props.lightMode === "App light" ? logoLight : logoDark}
              alt="onlytech industries logo"
            />
          </Col>
          <Col>
            <span
              onClick={() => props.setPrivacyPolicy(true)}
              className="privacy-link"
            >
              {testi[props.language].footer[0]}
            </span>
          </Col>
          <Col>
            <a
              href="https://www.linkedin.com/company/onlytech-industries/"
              target="_blank"
              rel="noreferrer"
              className="d-flex align-items-center"
            >
              <Linkedin className={props.lightMode} />
              <span className="ms-2">LinkedIn</span>
            </a>
          </Col>
          <Col>
            <span className="">P.Iva: 04346880240</span>
          </Col>
        </Row>
      </m.div>
    </div>
  );
};

export default MobileFooter;
