import React from "react";
import { motion as m } from "framer-motion";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import Navigation from "../components/Navigation";
import { Linkedin } from "react-bootstrap-icons";
import astrolight from "../media/astro-light.webp";
import astrodark from "../media/astro-dark.webp";
import testi from "../data/testi.json";
import Privacy from "../components/Privacy";
import MobileFooter from "../components/MobileFooter";
import { Helmet } from "react-helmet-async";
import logohome from "../media/logoHome.png";
import ServiceThumb from "../media/ServiceThumb.png";
import AboutUsThumb from "../media/AboutUsThumb.png";
const EasterEgg = (props) => {
  return (
    <div>
      <img className="w-full h-full" src={logohome} />
      <img className="w-full h-full" src={ServiceThumb} />
      <img className="w-full h-full" src={AboutUsThumb} />
    </div>
  );
};

export default EasterEgg;
