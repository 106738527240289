import React, { useEffect, useRef, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { observer } from "mobx-react-lite";
import { Toaster } from "react-hot-toast";
import "../style/App.scss";
import "tailwindcss/tailwind.css";

function Modal(props) {
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    if (props.isOpen) {
      const html = document.body.parentNode;
      html.classList.add("modal_close");
    }
  }, [props.isOpen]);

  const onClose = () => {
    props.onClose();
    setTimeout(() => {
      const html = document.body.parentNode;
      html.classList.add("modal_close");
      html.removeAttribute("style");
    }, 200);
  };

  return (
    <>
      <Toaster position="top-right" />
      <Transition.Root show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          style={{ zIndex: 0 }}
          className="fixed inset-0 h-screen w-full overflow-y-scroll lg:overflow-y-auto backdrop-blur-sm backdrop-filter bg-black bg-opacity-25 transition-opacity"
          initialFocus={cancelButtonRef}
          open={props.isOpen}
          onClose={onClose}
        >
          <div className="flex h-screen my-6 md:my-0 w-full items-center justify-center text-sm text-white">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 h-screen py-6 md:py-0  " />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className={
                  props.lightMode == "App light"
                    ? "light relative bottom-modal my-auto w-11/12 h-fit rounded-[21px] primary sm:w-20 lg:bottom-0 lg:w-25"
                    : "dark relative bottom-modal my-auto w-11/12 h-fit rounded-[21px] primary sm:w-20 lg:bottom-0 lg:w-25"
                }
              >
                <div className=" ">
                  <div className="px-1 pt-1 sm:px-2 sm:pt-2">
                    <div className="sm:flex sm:items-start">
                      <div className=" w-full sm:mt-3">{props.children}</div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export const SimpleModal = observer(Modal);
