import Home from "./pages/Home";
import News from "./pages/News";
import Services from "./pages/Services";
import { motion as m } from "framer-motion";
import "./style/App.scss";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Onlymusix from "./pages/Onlymusix";
import { useEffect, useState } from "react";
import Error404 from "./pages/Error404";
import Popupevents from "./pages/Popupevents";
import AboutUs from "./pages/AboutUs";
import { styledToast } from "./pages/StyledToast";
import { Toaster } from "react-hot-toast";
import PreviewImg from "./pages/SeoImg";
import { HelmetProvider } from "react-helmet-async";
import EasterEgg from "./pages/Egg";

function App() {
  const [lightMode, setLightMode] = useState("App light");
  const [language, setLanguage] = useState("it");
  const [privacyPolicy, setPrivacyPolicy] = useState(false);

  return (
    <>
      <m.div className={lightMode}>
        <Router>
          <Routes>
            <Route
              path="/3ast3r3gg"
              element={
                <EasterEgg
                  setLightMode={setLightMode}
                  lightMode={lightMode}
                  language={language}
                  setLanguage={setLanguage}
                  privacyPolicy={privacyPolicy}
                  setPrivacyPolicy={setPrivacyPolicy}
                />
              }
            />
            <Route
              path="/"
              element={
                <Home
                  setLightMode={setLightMode}
                  lightMode={lightMode}
                  language={language}
                  setLanguage={setLanguage}
                  privacyPolicy={privacyPolicy}
                  setPrivacyPolicy={setPrivacyPolicy}
                />
              }
            />
            <Route
              path="/news"
              element={
                <News
                  setLightMode={setLightMode}
                  lightMode={lightMode}
                  language={language}
                  setLanguage={setLanguage}
                  privacyPolicy={privacyPolicy}
                  setPrivacyPolicy={setPrivacyPolicy}
                />
              }
            />
            <Route
              path="/services"
              element={
                <Services
                  setLightMode={setLightMode}
                  lightMode={lightMode}
                  language={language}
                  setLanguage={setLanguage}
                  privacyPolicy={privacyPolicy}
                  setPrivacyPolicy={setPrivacyPolicy}
                />
              }
            />
            <Route
              path="/onlymusix"
              element={
                <Onlymusix
                  setLightMode={"App dark"}
                  lightMode={"App dark"}
                  language={language}
                  setLanguage={setLanguage}
                  privacyPolicy={privacyPolicy}
                  setPrivacyPolicy={setPrivacyPolicy}
                />
              }
            />
            <Route
              path="/about"
              element={
                <AboutUs
                  setLightMode={setLightMode}
                  lightMode={lightMode}
                  language={language}
                  setLanguage={setLanguage}
                  privacyPolicy={privacyPolicy}
                  setPrivacyPolicy={setPrivacyPolicy}
                />
              }
            />
            <Route
              path="/Popupevents"
              element={
                <Popupevents
                  setLightMode={"App dark"}
                  lightMode={"App dark"}
                  language={language}
                  setLanguage={setLanguage}
                  privacyPolicy={privacyPolicy}
                  setPrivacyPolicy={setPrivacyPolicy}
                />
              }
            />
            <Route
              path="*"
              element={
                <Error404
                  setLightMode={setLightMode}
                  lightMode={lightMode}
                  language={language}
                  setLanguage={setLanguage}
                  privacyPolicy={privacyPolicy}
                  setPrivacyPolicy={setPrivacyPolicy}
                />
              }
            />
          </Routes>
        </Router>
      </m.div>
    </>
  );
}

export default App;
