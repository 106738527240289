import React from "react";
import corriereBlack from "../media/Corriere-della-SeraBlack.png";
import corriere from "../media/Corriere-della-Sera.png";
import forbes from "../media/Forbes.png";
import forbesBlack from "../media/ForbesBlack.png";
import repubblica from "../media/larepubblica.png";
import repubblicaBlack from "../media/larepubblicaBlack.png";
import decrypto from "../media/Logo-decripto.png";
import rockolBlack from "../media/RockolBlack.png";
import rockol from "../media/Rockol.png";
import wsi from "../media/Wall-Street-Italia.png";
import wsiblack from "../media/Wall-Street-ItaliaBlack.png";
import rtl from "../media/RTL 102.5.png";
import { motion as m } from "framer-motion";
import "tailwindcss/tailwind.css";
import "../style/App.scss";

const Partners = (props) => {
  return (
    <div className="flex flex-col md:flex-row justify-around">
      <div className="flex w-full md:w-1/2 mx-auto lg:w-full flex-col mr-2 my-auto">
        {" "}
        <a
          target="_blank"
          className="text-black"
          href="https://drive.google.com/open?id=1c76AMzBvgj5brYjQRN7g9hINXVoBxXky&usp=drive_fs"
        >
          <img
            className="flex w-1/2"
            src={props.lightMode === "App light" ? repubblicaBlack : repubblica}
          />
        </a>
        <a
          target="_blank"
          className="text-black"
          href="https://www.corriere.it/buone-notizie/22_giugno_07/opera-d-arte-visiva-digitale-arrivano-nft-musicali-personaggi-cultura-pop-2448149e-e64d-11ec-864b-88ccbc1cac69.shtml"
        >
          <img
            className="flex  w-3/4"
            src={props.lightMode === "App light" ? corriereBlack : corriere}
          />
        </a>
        <div className="flex mb-2 flex-row justify-between">
          <div className="flex flex-col my-auto">
            <a
              target="_blank"
              className="text-black"
              href="https://www.rockol.it/news-723734/nasce-onlymusix-piattaforma-italiana-per-lo-scambio-di-nft-musicali"
            >
              <img
                className="flex "
                src={props.lightMode === "App light" ? rockolBlack : rockol}
              />
            </a>
            <a
              target="_blank"
              className="text-black"
              href="https://forbes.it/2023/02/07/casa-sanremo-guarda-al-futuro-e-sceglie-il-biglietto-digitale-il-pass-2023-sara-un-nft/"
            >
              <img
                className="flex"
                src={props.lightMode === "App light" ? forbesBlack : forbes}
              />
            </a>
          </div>
          <div className="flex ">
            <a
              target="_blank"
              className="my-auto mx-3 w-full"
              href="https://play.rtl.it/ospiti/35/onlymusix-nft-e-musica-come-si-relazionano-venerdi-16-luglio-2021/"
              rel="noopener noreferrer"
            >
              <img className=" w-full h-full cursor-pointer" src={rtl} />
            </a>
            <a
              target="_blank"
              className="text-black my-auto"
              href="https://decripto.org/il-concertone-del-primo-maggio-sbarca-nel-web3-arriva-lnft-ufficiale-firmato-decriptoonlymusix-esclusiva/"
            >
              <img className="flex w-1/2 h-fit my-auto" src={decrypto} />
            </a>
          </div>
        </div>
        <a
          target="_blank"
          className="text-black"
          href="https://www.wallstreetitalia.com/gli-nft-sbarcano-al-festival-di-sanremo/"
        >
          <img
            className="flex w-2/3"
            src={props.lightMode === "App light" ? wsiblack : wsi}
          />
        </a>
      </div>
      <div className="flex w-full md:w-1/2 lg:w-1/2 xl:w-full my-auto">
        {props.lightMode === "App light" ? (
          <>
            <div className="border-[1px] mx-auto  border-black rounded-[21px] d-md-block w-[540px] h-fit m-3 p-4 md:py-4 md:px-5">
              <div>
                <div className="text-xl pb-3"> PARTNERSHIP:</div>

                <div className="flex flex-col md:flex-row ">
                  <div className="flex text-xs lg:text-base text-left flex-col xl:text-xl xxl:text-xl w-1/2 ">
                    <ul className="list">
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://www.youtube.com/watch?v=Crsh79b6alw
"
                        >
                          Confindustria
                        </a>
                      </li>
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://invest.casasanremo.it/event/antonino-abbate/"
                        >
                          CasaSanremo
                        </a>
                      </li>
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://www.ilmessaggero.it/roma/eventi/concertone_primo_maggio_roma-7368901.html"
                        >
                          ICompany
                        </a>
                      </li>
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://decripto.org/3-giorni-di-web3-e-musica-nei-quartieri-spagnoli-con-spaghetteth-e-onlymusix/
"
                        >
                          Spaghett ETH
                        </a>
                      </li>
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://musicbiz.rockol.it/news-735609/quardisc-partnership-onlymusix-per-scambio-nft
"
                        >
                          QUARdisc
                        </a>
                      </li>
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://app.forestmatic.com/dashboard/clients/onlymusix/
"
                        >
                          Forestmatic
                        </a>
                      </li>
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://www.opensoundfestival.eu/osa-2023/
"
                        >
                          Opensound
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="flex text-xs lg:text-base text-left flex-col xl:text-xl xxl:text-xl w-1/2 ">
                    <ul className="list">
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://2023.linecheck.it/meeting/speaker/silvia-bertelli/
"
                        >
                          Linecheck
                        </a>
                      </li>
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://www.mondo.nyc/poster2/artists%2C-labels-%26-nfts%3A-onlymusix-deconstructs-the-nft-marketplace"
                        >
                          Mondo.NYC
                        </a>
                      </li>
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://www.musicandthecity.it/resetfestival-il-festival-dellinnovazione-musicale-entra-nei-live-con-cristina-dona-ghemon-e-bianco/
"
                        >
                          {" "}
                          _resetfestival
                        </a>
                      </li>
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://bari.ilquotidianoitaliano.com/eventi/2022/07/news/medimex-2022-gli-ospiti-della-seconda-giornata-la-michielin-speciale-frank-zappa-showcase-e-il-live-dei-the-chemical-brothers-356256.html/
"
                        >
                          Medimex
                        </a>
                      </li>
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://meiweb.it/2022/09/20/l-mei-25-incontro-su-nft-e-la-musica-coi-belladonna-quale-futuro/"
                        >
                          MEI
                        </a>{" "}
                      </li>
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href=" https://transak.com/
"
                        >
                          Transak
                        </a>
                      </li>
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://decripto.org/il-concertone-del-primo-maggio-sbarca-nel-web3-arriva-lnft-ufficiale-firmato-decriptoonlymusix-esclusiva/
"
                        >
                          Decripto.org
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="border-[1px] mx-auto  border-white rounded-[21px] d-md-block w-[540px] h-fit m-3 py-4 px-5">
              <div>
                <div className="text-xl pb-3"> PARTNERSHIP:</div>

                <div className="flex flex-col md:flex-row ">
                  <div className="flex text-xs lg:text-base text-left flex-col xl:text-xl xxl:text-xl w-1/2 ">
                    <ul className="list">
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://www.youtube.com/watch?v=Crsh79b6alw
"
                        >
                          Confindustria
                        </a>
                      </li>
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://invest.casasanremo.it/event/antonino-abbate/"
                        >
                          CasaSanremo
                        </a>
                      </li>
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://www.ilmessaggero.it/roma/eventi/concertone_primo_maggio_roma-7368901.html"
                        >
                          ICompany
                        </a>
                      </li>
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://decripto.org/3-giorni-di-web3-e-musica-nei-quartieri-spagnoli-con-spaghetteth-e-onlymusix/
"
                        >
                          Spaghett ETH
                        </a>
                      </li>
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://musicbiz.rockol.it/news-735609/quardisc-partnership-onlymusix-per-scambio-nft
"
                        >
                          QUARdisc
                        </a>
                      </li>
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://app.forestmatic.com/dashboard/clients/onlymusix/
"
                        >
                          Forestmatic
                        </a>
                      </li>
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://www.opensoundfestival.eu/osa-2023/
"
                        >
                          Opensound
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="flex text-xs lg:text-base text-left flex-col xl:text-xl xxl:text-xl w-1/2 ">
                    <ul className="list">
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://2023.linecheck.it/meeting/speaker/silvia-bertelli/
"
                        >
                          Linecheck
                        </a>
                      </li>
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://www.mondo.nyc/poster2/artists%2C-labels-%26-nfts%3A-onlymusix-deconstructs-the-nft-marketplace"
                        >
                          Mondo.NYC
                        </a>
                      </li>
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://www.musicandthecity.it/resetfestival-il-festival-dellinnovazione-musicale-entra-nei-live-con-cristina-dona-ghemon-e-bianco/
"
                        >
                          {" "}
                          _resetfestival
                        </a>
                      </li>
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://bari.ilquotidianoitaliano.com/eventi/2022/07/news/medimex-2022-gli-ospiti-della-seconda-giornata-la-michielin-speciale-frank-zappa-showcase-e-il-live-dei-the-chemical-brothers-356256.html/
"
                        >
                          Medimex
                        </a>
                      </li>
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://meiweb.it/2022/09/20/l-mei-25-incontro-su-nft-e-la-musica-coi-belladonna-quale-futuro/"
                        >
                          MEI
                        </a>{" "}
                      </li>
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href=" https://transak.com/
"
                        >
                          Transak
                        </a>
                      </li>
                      <li className="mt-1">
                        <a
                          target="_blank"
                          className="textLink"
                          href="https://decripto.org/il-concertone-del-primo-maggio-sbarca-nel-web3-arriva-lnft-ufficiale-firmato-decriptoonlymusix-esclusiva/
"
                        >
                          Decripto.org
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Partners;
