import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { motion as m } from "framer-motion";
import testi from "../data/testi.json";
const NewsDisclaimer = ({ language, setNews, article, index }) => {
  return (
    <m.div
      className="secondary  d-flex align-items-center"
      initial={{ height: "0%", display: "none" }}
      animate={{ height: "100%", display: "block" }}
      transition={{ duration: 1, delay: 0.3 }}
    >
      <m.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div
          onClick={() => setNews(index)}
          style={{ cursor: "pointer" }}
          className="news-disclaimer"
        >
          <Row>
            <Col>
              <p className="subtitle2 lg:text-[35px] mb-3">{article.titolo}</p>
              <p className="main-text ">{article.introduzione}</p>

              <p className="">
                {article.data}{" "}
                <a className="boldText lg:pl-4 text-lg">
                  {" "}
                  {testi[language].readMore}
                </a>{" "}
              </p>
            </Col>
            {article.img ? (
              <Col xs="auto" className="d-none d-md-block">
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {article.img}
                </ReactMarkdown>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </div>
      </m.div>
    </m.div>
  );
};

export default NewsDisclaimer;
