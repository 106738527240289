import React from "react";
import { Container, Modal } from "react-bootstrap";
import policies from "../data/privacy-policies.json";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const Privacy = (props) => {
  return (
    <div className="Privacy">
      <Modal
        show={props.privacyPolicy}
        fullscreen={true}
        onHide={() => props.setPrivacyPolicy(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {policies[props.language].policy[0]}
            </ReactMarkdown>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {policies[props.language].policy[1]}
            </ReactMarkdown>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Privacy;
