import toast from "react-hot-toast";

export const styledToast = (text) => {
  toast((t) => (
    <div className="flex flex-row text-black bold z-100">
      <div className="p-1">
        <p className="my-auto">{text}</p>
      </div>
      <div className="flex items-center p-1 text-accent">
        <button
          className="flex align-middle"
          onClick={() => toast.dismiss(t.id)}
        >
          x
        </button>
      </div>
    </div>
  ));
};
