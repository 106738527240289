import React, { useEffect, useRef, useState } from "react";
//Style
import testi from "../data/testi.json";
import "tailwindcss/tailwind.css";
import axios from "axios";
import { styledToast } from "./StyledToast.js";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
const EmailComponent = (props) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");

  const [consultancy, setconsultancy] = useState(false);
  const [privacy, privacyPolicy] = useState(true);
  const [emailValid, setEmailValid] = useState(false);
  const [nameValid, setNameValid] = useState(false);
  const [surnameValid, setSurnameValid] = useState(false);

  const emailList = [];
  const [showEmailError, setShowEmailError] = useState(null);
  useEffect(() => {
    console.log(props.lightMode);
  }, [props.lightMode]);
  useEffect(() => {
    console.log(consultancy);
  }, [consultancy]);
  useEffect(() => {
    console.log(privacy);
  }, [privacy]);
  const sendinBlueSubscribe = async (email, name, surname) => {
    if (consultancy) {
      emailList.push(29); //consultancy list
    }
    if (privacy) {
      emailList.push(28); //privacy list
    }
    console.log(emailList);
    console.log(name);
    const apiKey =
      "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";
    try {
      const response = await axios.put(
        `https://api.sendinblue.com/v3/contacts/${email}`,
        {
          email,
          attributes: { NOME: name, COGNOME: surname }, // And this line
          listIds: emailList,
        },
        {
          headers: {
            "api-key": apiKey,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Sendinblue API Response:", response.data);

      return response.data;
    } catch (error) {
      try {
        const response = await axios.post(
          "https://api.sendinblue.com/v3/contacts",
          {
            email,
            attributes: { NOME: name, COGNOME: surname }, // And this line
            listIds: emailList,
          },
          {
            headers: {
              "api-key": apiKey,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("Sendinblue API Response:", response.data);

        return response.data;
      } catch (error) {
        console.error("Sendinblue API Error:", error);
        throw error;
      }
    }
  };

  /*const sendinBlueSubscribe = (email) => {
    const SibApiV3Sdk = require("@getbrevo/brevo");
    let apiInstance = new SibApiV3Sdk.ContactsApi();

    let apiKey = apiInstance.authentications["apiKey"];
    apiKey.apiKey =

    let createContact = new SibApiV3Sdk.CreateContac();
    createContact.email = email;

    return apiInstance.createContac(createContact);
  };*/
  const validateEmail = (currentEmail) => {
    const emailIsValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(currentEmail);

    setEmailValid(emailIsValid);

    setEmail(currentEmail);
  };
  const validateName = (currentName) => {
    const nameIsValid = /^[a-zA-Z]+$/.test(currentName);

    setNameValid(nameIsValid);
    console.log("isvalud" + nameIsValid);
    setName(currentName);
  };
  const validateSurname = (currentSurname) => {
    const surnameIsValid = /^[a-zA-Z]+$/.test(currentSurname);

    setSurnameValid(surnameIsValid);
    console.log("isvalud" + surnameIsValid);

    setSurname(currentSurname);
  };
  const onSubscribe = (evt) => {
    console.log(email);
    console.log(name);
    console.log(surname);
    evt.preventDefault();

    setShowEmailError(true);
    validateEmail(email);

    if (emailValid === false || nameValid === false || surnameValid === false) {
      styledToast(testi[props.language].toaster.error);
      return;
    }

    sendinBlueSubscribe(email, name, surname)
      .then((res) => {
        console.log("guardia");
        styledToast(testi[props.language].toaster.subscribe);
        setEmail("");
        props.onClose();
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.body &&
          err.response.body.code === "duplicate_parameter"
        ) {
          styledToast(testi[props.language].toaster.error);
        } else if (err.response && err.response.body) {
          styledToast(testi[props.language].toaster.error);
        } else {
          console.error(err);
          console.log("guardia 2");
          styledToast(testi[props.language].toaster.error);
        }
      });
  };

  return (
    <div className="h-fit my-4 lg:my-7 max-w-1440 mx-auto ">
      <form>
        <p className=" px-4 text-base lg:text-xl mx-auto boldtext font-black	">
          {testi[props.language].emailForm.title}
        </p>
        <div className="flex flex-col md:flex-row px-3">
          <div className="mt-2">
            <p className=" mx-auto text-base lg:text-xl">
              {testi[props.language].emailForm.name[0]}
            </p>
            <input
              type="name"
              value={name}
              onChange={(evt) => validateName(evt.target.value)}
              class="mr-3 flex text-xs p-3 md:p-[25px]  text-custom text-black rounded-xl rounded-s-gray-100 rounded-s-2 border-black border-[1.5px] onlytechGray"
              placeholder={testi[props.language].emailForm.placeholder[0]}
              required
            />
          </div>
          <div className="mt-2">
            <p className=" mx-auto text-base lg:text-xl">
              {testi[props.language].emailForm.name[1]}
            </p>
            <input
              type="surname"
              value={surname}
              onChange={(evt) => validateSurname(evt.target.value)}
              class="flex text-xs p-3 md:p-[25px]  text-custom  rounded-xl text-black rounded-s-gray-100 rounded-s-2 border-black border-[1.5px] onlytechGray"
              placeholder={testi[props.language].emailForm.placeholder[1]}
              required
            />
          </div>
        </div>

        <p className=" mx-auto text-base lg:text-xl px-3 lg:px-4 pt-4 ">
          {" "}
          {testi[props.language].emailForm.insert[0]}
        </p>
        <div class="flex w-11/12 my-4 mx-auto">
          <div class="relative w-full ">
            {nameValid && surnameValid ? (
              <input
                type="email"
                value={email}
                onChange={(evt) => validateEmail(evt.target.value)}
                class="text-black block text-xs p-3 md:p-[25px] w-full text-custom  rounded-xl rounded-s-gray-100 rounded-s-2 border-black border-[1.5px]  onlytechGray"
                placeholder={testi[props.language].emailForm.insertEmail}
                required
              />
            ) : (
              <input
                type="email"
                value={email}
                onChange={(evt) => validateEmail(evt.target.value)}
                class="text-black block text-xs p-3 md:p-[25px] w-full text-custom  rounded-xl rounded-s-gray-100 rounded-s-2 border-black border-[1.5px]  onlytechGray"
                placeholder={testi[props.language].emailForm.select}
                required
                disabled={false}
              />
            )}
            {nameValid && surnameValid && emailValid ? (
              <button
                type="submit"
                onClick={onSubscribe}
                class="absolute top-0 end-0 p-1 md:p-3.5 h-full w-1/3 lg:w-1/4 md:text-lg text-white rounded-xl border-black focus:outline-none onlytechBlue"
              >
                {testi[props.language].emailForm.insert[1]}
              </button>
            ) : (
              <button
                type="button"
                onClick={(evt) => {
                  styledToast(testi[props.language].toaster.options);
                }}
                class="absolute top-0 end-0 p-1 md:p-3.5 h-full w-1/3 lg:w-1/4 md:text-xs text-white  rounded-xl border-black focus:outline-none bg-gray-500"
              >
                {testi[props.language].emailForm.insert[1]}
              </button>
            )}
          </div>
        </div>
      </form>
      <div className="mx-4 xl:mx-7">
        <div class="flex flex-row items-start mt-2 md:mt-4">
          {/* privacy checkbox */}
          <p
            className="text-xxs lg:text-sm  text-left"
            style={{ display: "inline" }}
          >
            {testi[props.language].emailForm.checkbox[0]}

            <span
              onClick={() => props.setPrivacyPolicy(true)}
              className="privacy-link boldText  text-blue-600 hover:text-blue-800 cursor-pointer	"
              style={{ display: "inline" }}
            >
              {testi[props.language].footer[0]}
            </span>
          </p>
          {/*
           <input
            type="checkbox"
            checked={privacy}
            onChange={() => {
              privacyPolicy(!privacy);
            }}
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            for="default-checkbox"
            class="ms-2 text-xxs lg:text-sm  text-left "
          >
            dummy
          </label>
           */}
        </div>
        <div class="flex items-start mt-2">
          {/* consultancy checkbox */}
          <input
            type="checkbox"
            checked={consultancy}
            onChange={() => {
              setconsultancy(!consultancy);
            }}
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            for="checked-checkbox"
            class="ms-2 text-xxs lg:text-sm  text-left "
          >
            {testi[props.language].emailForm.checkbox[1]}
          </label>
        </div>
      </div>
    </div>
  );
};

export default EmailComponent;
